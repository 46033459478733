<template>
  <Transition>
    <div class="transferNoteRoot">
      <FormKit type="form" id="transferNoteWrapper" :actions="false" @submit="submitForm">
        <FormKit type="multi-step"
            name="transferNote"
            tab-style="progress" :hide-progress-labels="true"
            class="grid grid-cols-1 place-items-stretch"
            :allow-incomplete="false"
        >
          <FormKit type="step" name="isPatient"
                     next-label="Weiter"
                     prev-label="Zurück"
                     :beforeStepChange="checkAllowedPatients"
          >
            <h2 class="text-2xl doroHeadline">{{texts.isPatientHeadline}}</h2>
            <div class="text-md mb-2 doroSubline">{{texts.isPatientSubLine}}</div>
            <FormKit
                v-model="formData.patientType"
                type="radio"
                name="Patientenart"
                preserve="true"
                validation="required"
                :options="[
                            { label:texts.isPatientNew , value: 'newPatient' },
                            { label:texts.isPatientExisting , value: 'existingPatient'}
                          ]"
            />
          <buttonFormReset />
        </FormKit>
          <FormKit type="step" name="transferReason"
                   next-label="Weiter"
                   prev-label="Zurück"
          >
            <h2 class="text-2xl doroHeadline">{{ texts.TN_Headline }}</h2>
            <div class="text-md mb-3 pt-0 text-left">{{ texts.TN_subLine }}</div>
            <FormKit
                v-model="formData.data.specialisttype"
                type="text"
                label="Fachrichtung"
                name="specialistType"
                validation="required"
                placeholder="Facharzt..."
            />
            <FormKit
                v-model="formData.data.transferreason"
                type="text"
                label="Überweisungsgrund"
                name="transferReason"
                validation="required"
                placeholder="Überweisungsgrund..."
            />
          </FormKit>
         <FormKit type="step" name="patientData"
                  :next-label="texts.nextLabel"
                  :prev-label="texts.prevLabel">
          <h2 class="text-2xl doroHeadline">{{texts.patientDataHeadline}}</h2>
          <div class="text-md mb-3 pt-0">{{texts.patientDataSubLine}}</div>
          <FormKit
           v-model="formData.firstname"
           type="text"
           :label="texts.formField.firstname.label"
           name="firstname"
           validation="required"
           :placeholder="texts.formField.firstname.placeholder"
          />
          <FormKit
           v-model="formData.name"
           type="text"
           :label="texts.formField.name.label"
           name="name"
           validation="required"
           :placeholder="texts.formField.name.placeholder"
          />
          <FormKit
           type="date"
           :label="texts.formField.birthday.label"
           name="birthday"
           :validation="texts.formField.birthday.validation"
           v-model="formData.birthday"
          />
         </FormKit>
         <FormKit type="step" name="patientContact"
                  :next-label="texts.nextLabel"
                  :prev-label="texts.prevLabel">
          <h2 class="text-2xl doroHeadline">{{texts.patientContactHeadline}}</h2>
          <div class="text-md mb-3 pt-0">{{texts.patientContactSubLine}}</div>
          <FormKit
           v-model="formData.email"
           type="email"
           :label="texts.formField.email.label"
           name="email"
           validation="required|email"
           :placeholder="texts.formField.email.placeholder"
          />
          <FormKit
           v-model="formData.phone"
           type="text"
           :label="texts.formField.phone.label"
           name="phone"
           :placeholder="texts.formField.phone.placeholder"
           :validation="texts.formField.phone.validation"

          />
         </FormKit>
         <FormKit v-if="texts.showForm_insuranceCard" type="step" name="insuranceCard"
                  :next-label="texts.nextLabel"
                  :prev-label="texts.prevLabel">
          >
          <h2 class="text-2xl doroHeadline">{{texts.insuranceCardHeadline}}</h2>
          <div class="text-md mb-2 doroSubLine">{{texts.insuranceCardSubLine}}</div>
          <FormKit
           v-model="formData.data.insuranceCard"
           type="radio"
           :name="texts.formField.insuranceCard.label"
           preserve="true"
           validation="required"
           :options="texts.formField.insuranceCard.options"
          />
         </FormKit>
          <FormKit type="step" name="summary" prev-label="Zurück">
            <h2 class="text-2xl doroHeadline">{{texts.summaryHeadline}}</h2>
            <div class="text-md mb-4 pt-0">{{texts.summarySubLine}}</div>
            <div class="text-left grid grid-cols-2 gap-2 mb-8">
              <div>Überweisung an:</div>
              <div>{{formData.data.specialisttype}}</div>
              <div>Überweisungsgrund:</div>
              <div>{{formData.data.transferreason}}</div>
              <div v-if="texts.showForm_insuranceCard">Chipkarte eingelesen:</div>
              <div v-if="texts.showForm_insuranceCard">{{formData.data.insuranceCard}}</div>
              <div class="col-span-2 mt-8">Ihre Daten:</div>
              <div>Vorname:</div>
              <div>{{formData.firstname}}</div>
              <div>Name:</div>
              <div>{{formData.name}}</div>
              <div v-if="formData.birthday">Geburtstag:</div>
              <div v-if="formData.birthday">{{format_date(formData.birthday)}}</div>
              <div>Email:</div>
              <div>{{formData.email}}</div>
              <div v-if="formData.phone">Telefonnummer:</div>
              <div v-if="formData.phone">{{formData.phone}}</div>
            </div>
            <FormKit
                :disabled="isLoading"
                type="submit"
                name="Senden"
                :config="{classes:{
                  outer:'formSendOuter formkit-step-next pr-5 text-right',
                  input:'doro_button'
                }}"
            />
          </FormKit>
        </FormKit>
      </FormKit>
      <div class="" v-if="submitError!==''">{{submitError}}</div>
    </div>
  </Transition>
</template>

<script>
import buttonFormReset from '@/components/ButtonFormReset.vue';
import moment from "moment/moment";
import router from "@/router"
export default {
  name: 'FormTransferNote',
  components: { buttonFormReset },
  inject:['userid'],
  methods : {
    format_date(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    submitForm() {
      this.$root.$options.methods.submitForm(this.formData)
    },
    checkAllowedPatients () {
      if(this.formData.patientType === 'newPatient'){
        router.push({ path: `/meldung/${this.formData.patientType}` })
      }
    }
  },
  data () {
    //useFormDataStore()
    let phone
    return {
      phone,
      texts: this.texts
    }
  },
  setup () {
    return {
      emits:['showNoNewPatients','clickFormReset'],
      result : 'showNoNewPatients',
      submitError : '',
      isLoading : false,
      formData: {
        type        : 'transferNote',
        patientType : '',
        firstname   : '',
        name        : '',
        birthday    : '',
        phone       : '',
        email       : '',
        data    : {
          specialisttype : '',
          transferreason : '',
          insuranceCard:''
        }
      }
    }
  }
}

</script>